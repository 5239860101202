import React from "react"

import Layout from "../../components/Layout"

import { Link } from "gatsby"

import img1 from "../../images/territorio/score1.png";
import img2 from "../../images/territorio/story1_r.png";
import img3 from "../../images/territorio/territorio_r.jpg";

export default function Home() {
  return (
    <Layout breadcrumbs={["Território em desenvolvimento"]}>
      <div id="heading3" >
        <h1>Território em desenvolvimento</h1>
      </div>

      {/* Main */}
      <section id="main" className="wrapper">
        <div className="inner">

          <div className="content page">

            {<h5><Link to="/">Inicio</Link> &gt;&gt; Projetos  &gt;&gt; Territórios em Desenvolvimento<span className="registered">®</span></h5>}

            <header>
              <h2>Pensando e colaborando pelos espaços da gente</h2>
            </header>          

            <div style={{ display: 'flex' }}>
              <div>
                <p className="headline">Bons indicadores nos ajudam não apenas a entender melhor o mundo, mas também a transformá-lo!</p>
                <p><span className="image right desktop-only"><img src={img1}/></span>
                O projeto “Território em Desenvolvimento” propõe-se a tarefa de informar e catalisar a cooperação de gestores públicos 
                e da sociedade civil, em favor dos Objetivos de Desenvolvimento Sustentável da ONU e da construção de territórios cada vez 
                melhores.</p> 
                <p>A peça central do projeto é o Índice de Progresso Social. 
                  O IPS é uma maneira inovadora de mensurar a qualidade de vida e o bem-estar de territórios, 
                  sejam bairros, cidades, regiões ou países. Ao se adaptar às realidades locais, o índice empodera comunidades para melhor 
                  cuidarem do seu entorno.</p>
                <span className="image-page mobile-only"><img src={img1} /></span>                                
                <p>A elaboração do IPS para 12 cidades do interior do Paraná é resultado de uma parceria entre a Instituição Klabin, 
                  a Fundação Avina, a Interação Urbana e Diferencial e o Instituto Agir Ambiental. 
                  O Engajados é responsável pela concepção e desenvolvimento da plataforma.</p>
                <span className="image-page mobile-only"><img src={img2} /></span>                                
                <p><span className="image right desktop-only"><img src={img2} /></span>
                Nosso site, em construção, oferecerá ao público interessado uma maneira mais didática de interagir com os dados, de se apropriar dos mesmos e de colaborar pelo bem do território. Gestores, servidores públicos, sociedade civil organizada, educadores, empresários e comunidades terão a sua disposição um espaço de informação e articulação territorial.</p>
                <span className="image-page mobile-only"><img src={img3} /></span>                                
                <p><span className="image left desktop-only"><img src={img3}/></span>
                Na primeira fase do projeto, o Instituto Agir Ambiental moderará a articulação de cinco grupos de trabalho, 
                dentro da plataforma, sobre os temas: água e saneamento, igualdade de gênero, segurança alimentar, coleta seletiva, 
                e acesso à educação básica. </p>
                <ul>
                  <li>Acesse o projeto em: <a href="http://territorio.app.br/" target="_blank">territorio.app.br</a></li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </section>
    </Layout>
  )
}
